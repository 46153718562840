.Call911{
    padding: 20px;
}

/* @import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap'); */

.Call911 .calculator {
    float: right;
    padding: 10px;
    height: 650px;
    width: 900px;
    margin: auto;
}
.Call911 .display-box {
    font-family: 'Orbitron', sans-serif;
    color: black;
    background-color: rgb(230,230,234);
    width: 100%;
    height: 100px;
    font-weight: 700;
    font-size: 28px;
    border: none;
    letter-spacing: 4px;
}

.Call911 #btn {
    background-color: #08bb0e;
    height: 80px;
    color: white;
}

.Call911 input[type=button] {
    font-family: 'Orbitron', sans-serif;
    border: none;
    background-color: rgb(230,230,234);
    color: black;
    font-weight: 900;
    font-size: 30px;
    margin:8px;
    width: 100px;
    text-align: center;
    height: 100px;    
    display:inline-block; 
    text-align:center;
}

.Call911 input:active[type=button] {    
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
}

.Call911 .call{
    border-radius:40px; 
    width:90px;     
    height:90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
}

