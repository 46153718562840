.headerContainer {
    padding: 0;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    zoom: 1.25;
}

.headerContainer .NotifHide {
    display: none !important;
}

.headerContainer .NotifShow {
    display: inline-flex !important;
}

.headerContainer .navbar input {
    background-color: white !important;
    background: transparent url("../../public/images/header/Search.png") no-repeat 5px center;
}

.headerContainer ul {
    margin-left: -60px;
    display: grid;
    grid-template-columns: 30% 24.3% 24.3% 24.3%;
}

.headerContainer li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerContainer li:hover {
    cursor: pointer !important;
}

.headerContainer #NotificationDIV {
    z-index: 1;
    position: absolute;
    top: 66px;
    background-color: white;
    border: 2px solid rgba(240, 100, 73);
    font-size: 13px;
    width: 50%;
    left: 25%;
    padding: 12px 9px 0px 10px;
    box-shadow: -10px 1px 30px black;
    color: black;
    font-family: Arial;
    text-align: top;
    align-items: top;
}

.headerContainer .DescContainer {
    white-space: pre-wrap;
    margin-left: 2px;
    margin-bottom: 3px;
}

.headerContainer .PointersContainer {
    margin-left: 2px;
    margin-bottom: 10;
}

.headerContainer .NotifBellImage {
    display: inline-flex;
    max-height: 25px;
    min-width: 21px;
    padding: 5px;
    background: #36382E;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
}

.headerContainer .btn-close {
    padding-right: 10px;
    padding-top: 4px;
    float: right;
}

.headerContainer .NotifShowMore {
    cursor: pointer;
}

.headerContainer .NotificationTitle {
    font-size: 15px;
    color: rgb(40, 116, 149);
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 2px;
}

.headerContainer .container-fluid {
    padding-left: 60px;
}

.headerContainer .headerLink1:hover {
    /* background-color: #a2422f; */
    /* background-color: #37392e; */
    /* opacity: 0.8; */
}

.headerContainer .headerLink2:hover {
    /* background-color: #37392e; */
    /* opacity: 0.8; */
}

.headerContainer .headerLink1 {
    border-bottom-left-radius: 14px;
    padding-left: 20px;
    margin-right: 3px;
}

.headerContainer .headerLink2 {
    margin: 4px 3px;
    padding: 0px;
}

.headerContainer .headerLink1 .navlink1 {
    padding: 0px !important;
    margin: 0px 13px;
    min-width: 55px;
    min-height: 55px;
    outline: 2px solid white;
    border-radius: 27.5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #45473A;
}

.headerContainer .headerLink2 .navlink2 {
    padding: 0px !important;
    margin: 0px;
    min-width: 59.5px;
    min-height: 58px;
    border: 2px solid white;
    border-radius: 27.5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #45473A;
}

.headerContainer .headerLink2 .navlink3 {
    padding: 0px !important;
    margin: 0px 13px;
    min-width: 55px;
    min-height: 55px;
    outline: 2px solid white;
    border-radius: 27.5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #45473A;
}

.headerContainer .headerLink2 .navlink4 {
    padding: 0px !important;
    margin: 0px 13px;
    min-width: 55px;
    min-height: 55px;
    outline: 2px solid white;
    border-radius: 27.5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #45473A;
}

.headerContainer #Loaderul {
    list-style-type: none;
    display: block !important;
}

.headerContainer .closed {
    display: none;
}

.headerContainer .MonitoredItemDoneOk {
    zoom: 0.5;
    margin-right: 10px;
}

.headerContainer .MonitoredItemLoading {
    zoom: 0.65;
    margin-right: 8px;
    margin-bottom: 5px;
}

.headerContainer .MonitoredItem {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: left;
}

.headerContainer .MonitoredItem:hover {
    cursor: default !important;
}

.headerContainer .ClickablePointers {
    cursor: pointer;
}

.headerContainer .CallEmergencyDropDown {
    color: white;
    position: absolute;
    top: 84.5px;
    left: 44px;
    border-radius: 5px;
    z-index: 1;
    background-color: rgb(240, 100, 73);
    padding: 2px;
    width: 220px !important;
    zoom: 0.78;
}

.headerContainer .CallEmergencyDropDown div {
    background-color: #36382E;
    margin: 0.6px;
    padding: 10px;
}

.headerContainer .CallEmergencyDropDown div:hover {
    font-weight: bold;
}

.headerContainer .CallEmergencyDropDown div:first-child {
    margin-bottom: 3px;
}

.headerContainer .hide {
    display: none;
}