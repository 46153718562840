.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* For header component */
.headerLink:hover{
  opacity: 0.5;
}

.headerLink:active{
  opacity: 0.3;
}

.footerLink:hover{
  opacity: 0.6;
}

.footerLink:active{
  opacity: 0.4;
}

#hide{
  display: none;
}

#show{
  display: block;
}

@media screen and (max-width:1486px) {
   div.Responsivity{min-height: 41.95vw !important;}
}

.modal-backdrop
{
    opacity:0.7 !important;
}