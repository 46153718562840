.containerHomePage {
    padding-top: 0%;
    padding-left: 3%;
    max-width: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: 37.8% 50.7%;
    grid-template-rows: 95%;
    column-gap: 4%;
    font-family: Arial, Helvetica, sans-serif;
}

.leftside {
    max-width: 42.8% !important;
}

.containerHomePage .item1 {
    border: 3px solid rgb(2, 91, 130);
    margin: -2px !important;
    margin-top: 2.5% !important;
    display: grid;
}


.containerHomePage .item2 {
    margin-top: 4%;
    padding-top: 0%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 90%;
}

.containerHomePage .QuickAccess {
    float: left;
    font-size: 23px;
    color: rgb(2, 91, 130);
}

.containerHomePage .SeeMore {
    margin-top:5px;
    float: right;
    font-size: 16px;
    color: rgb(255, 64, 0) !important;
}

.containerHomePage .SeeMore:hover {
    zoom: 1.1;
}

.containerHomePage .Cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3%;
    column-gap: 2.5%;
}

.containerHomePage .Card {
    border: 2px solid rgb(2, 91, 130);
    border-radius: 15px;
    background-color: white;
    display: grid;
    grid-template-rows: 65% 35%;
    color: rgb(2, 91, 130);
    text-align: center;
    font-size: 23px;
    padding-top: 10px;
    padding-bottom: 10px;
    zoom: 1.13;
}

.containerHomePage .CardText {
    margin-left: 30%;
    margin-right: 30%;
}

.containerHomePage .Card:hover {
    box-shadow: 2px 2px 4px #000000;
}

.containerHomePage .hide{
    display: none;
}