.containerHelp {    
    padding-right: 10px;
    padding: 20px 20px 20px 20px;
    display: grid;
    grid-template-columns: 28% 0.2% 70%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.containerHelp .VehicleMonitoring{
    position: relative;
    text-align: center;
}
    
.containerHelp .MonitoredItem{
    font-size: 16px;
    border: 0px;
}

.containerHelp .VehicleMonitoring li{
    cursor: default;
}

.containerHelp .MonitoredItemLoading{
    margin-right: 15px;
}

.containerHelp .MonitoredItem .MonitoredItemDoneOk{
    margin-right: 15px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    zoom: 0.8 !important;
}

.containerHelp .MonitoredItem{
    display: flex;    
    align-items: center;
    vertical-align: middle;
}

.containerHelp .vl {
    border-left: 2px solid black;
    height: 90%;
    margin-top: 30px;
}

.containerHelp .FAQ{
    position: relative;
}

.containerHelp .title{
    padding-bottom: 18px;
}

.containerHelp ul{
    list-style-type: none;
}

.containerHelp .FAQ li:hover{
    background-image: linear-gradient(to right,rgb(216, 199, 199),rgb(244, 230, 230));        
}

.containerHelp li{
    border-bottom: 1px solid #d2d2d2;
    text-align: left;
    cursor: pointer;
    font-size: 22px;
    padding: 10px;
    margin: 10px;
}

.containerHelp .addIcon{
    margin-right: 4px;
}

.containerHelp .closed{
    display: none;
}

.containerHelp .opened{
    display: block;
}

.containerHelp .Answer{
    font-size: 17px;
    padding: 10px;
    margin-left: 24px;
}

.containerHelp a{
    font-size: 18px;
}

.containerHelp a:hover{
    color: rgb(36, 36, 210) !important;
    font-size: 20px;
}
