.controllerContainer .ScenarioLink{
    text-decoration: none !important;
    color: blue;
}

.controllerContainer .RadioButtonScenario{
    display: inline-block;
    margin: 10px;
}

.controllerContainer .LabelScenario{
    margin-left: 3px;
}

.controllerContainer .hide{
    display: none;
}

.controllerContainer .show{
    display: show;
}

.controllerContainer .PointerElem{
    margin-top: 5px;
}

.controllerContainer ul{
    list-style-type: none;
    margin-left: -60px;
}

.controllerContainer .closed{
    display: none;
}

.controllerContainer .MonitoredItemDoneOk{
    zoom: 0.75;
}

.controllerContainer .MonitoredItemLoading{
    zoom: 0.75;
    margin-right: 8px;
    margin-bottom: 5px;
}

.controllerContainer li{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 670px) {
    .availableAlerts{
        width: 95% !important;
    }

    form div.alert{
        width: 98% !important;
    }

    form div span{
        width: 84% !important;
    }

    div.list-notifs div:first-of-type{
        margin-bottom: 10px !important;
        margin-top: 10px;
    }

    .availableAlerts div:first-of-type{
        margin-top:0px !important;
    }

    .ScenarioLink{
        display: block !important;
    }

    hr:nth-child(3){
        display: none !important;
    }

    .ScenarioLink button{
        float: left !important;
        margin-top: 10px;
    }

    h2{
        font-size: 21.4px !important;
    }

    .controllerContainer{
        margin: 20px 20px !important;
    }

    .ScenarioLink:nth-child(2) button{
        margin-left: 0px !important;
    }
    
    .Disclamiers{
        float: left !important;
    }
  }