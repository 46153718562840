.containerMap {
    padding-top: 2.6%;
    padding-left: 3.5%;
    max-width: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: 28% 31% 28%;
    grid-template-rows: 95%;
    column-gap: 3%;
    font-family: Arial, Helvetica, sans-serif;
}

.containerMap .item1 {
    background-image: url("../../public/images/Map.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.containerMap .item2 {
    display: grid;
    row-gap: 7%;
}

.containerMap .showall:hover {
    /* color: blue !important; */
}

.containerMap .steps {
    background-image: url("../../public/images/Step-by-Step-Directions.png");
    background-size: 100%;
    background-repeat: no-repeat;
}

.containerMap .item3 {
    display: grid;
    grid-template-rows: 40% 31%;
}

.containerMap .item3 .alert {
    display: grid;
    grid-template-columns: 14% 70%;
    cursor: pointer;
}

.containerMap .item3 .info:hover {
    cursor: pointer;
}

.tool_tip {
    position: relative;
    display: inline-block;
}

.tool_tip .tooltip_text {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 145%;
    font-size: 15px !important;
    opacity: 0;
}

.tool_tip .tooltip_text::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}

.tool_tip:hover .tooltip_text {
    visibility: visible;
    cursor: default;
    color: white;
    opacity: 1;
    transition: opacity 1s;    
}

.containerMap input[type="checkbox"]{
    cursor: pointer;
    margin-right: 7%;
    -webkit-appearance: none;
    position: relative;
    background-color: rgb(198, 193, 193);
    width: 50px;
    height: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}

.containerMap input[type="checkbox"]::before{
    content:"";
    position: absolute;
    height: 20px;
    width: 20px;
    left: 0;
    background-color: rgb(125, 125, 125);
    border-radius: 10px;
    transform: scale(1.2);
    transition: 0.7s ease all;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}

.containerMap input:checked[type="checkbox"]::before{
    background-color: rgb(4, 166, 236) ;
    left: 41px;
}

.containerMap input:checked[type="checkbox"]{
    background-color: rgb(198, 231, 246, 1);    
}

.containerMap ul{
    padding-left: 0px;
}

.containerMap .alert:hover{
    box-shadow:  2px 2px 4px #000000;
}

.containerMap .SpacingsForArrows{
    margin:60px 0px 40px 20px;
    display:block;
}

.containerMap .DrawHorizontalLine{
    margin-left: 5px;
    display: inline-block;
    width: 90%;
    border-top: 1px solid #ccc
}